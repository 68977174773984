import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Map from '@material-ui/icons/Map';
import Phone from '@material-ui/icons/Phone';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import logo from './logo.png';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: { main: "#bd78d9" },
    secondary: { main: '#7f4596' }
  }
});

const buttonStyle = {
  width: "16em",
  backgroundColor: "#fff",
  color: "#150110",
  margin: "0.5em",
  borderRadius: "10px"
};

const buttonTextStyle = {
  flex: 1,
  textAlign: 'center'
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directions_key: ""
    };
  }

  handleDirectionsClick = (directions_key) => {
    this.setState({ directions_key });
  }

  handleMapsClick = () => {
    window.open("https://www.google.com/maps/dir//53.6598702,-6.6792299/@53.65987,-6.67923,16z", "_blank");
  }

  handleTelClick = () => {
    document.location.href="tel:+353871741160";
  }

  render() {
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="App-title">How to reach Joe</h1>
            <div>
              <Button style={buttonStyle} onClick={() => this.handleTelClick()}>
                <Avatar>
                  <Phone/>
                </Avatar>
                <div style={buttonTextStyle}>
                  087-1741160
                </div>
              </Button>
            </div>
            <Button style={buttonStyle} onClick={() => this.handleMapsClick()}>
              <Avatar>
                <Map/>
              </Avatar>
              <div style={buttonTextStyle}>
                DIRECTIONS
              </div>
            </Button>
          </header>
          <iframe
            title="google maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.276580438421!2d-6.68141858380081!3d53.65987018004592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486744ca15328821%3A0x71796d9ad02a7747!2s20%20St%20Mary&#39;s%20Park%2C%20Blackcastle%2C%20Navan%2C%20Co.%20Meath%2C%20C15%20T6F5!5e0!3m2!1sen!2sie!4v1592499946994!5m2!1sen!2sie"
            width="100%"
            height="450"
            frameborder="0"
            style={{
              border: "0",
              maxWidth: "69em",
            }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          >
          </iframe>
        </MuiThemeProvider>
      </div>
    );
  }
};

export default App;
